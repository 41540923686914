import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Helmet } from 'react-helmet';

import ContentWrapper from '../components/contentWrapper';
import aboutUsImg from '../images/who-we-are.jpg';
import LJLogo from '../images/laJardinera.png';
import styles from '../styles/pagesComponents/aboutUsPage.module.scss';

const AboutUsPage = ({ intl }) => (
  <ContentWrapper>
    <Helmet>
      <title>{intl.formatMessage({ id: 'menu.aboutUs' })}</title>
      <meta name="description" content={intl.formatMessage({ id: 'meta.description.aboutUs' })} />
      <meta property="og:title" content={intl.formatMessage({ id: 'menu.aboutUs' })} />
      <meta property="og:description" content={intl.formatMessage({ id: 'meta.description.aboutUs' })} />
    </Helmet>
    <div className={styles.imgContainer}>
      <img className={styles.aboutImg} src={aboutUsImg} alt={intl.formatMessage({ id: 'aboutUs.groupImgAltText' })} />
      <div className={styles.container}>
        <h2>
          <FormattedMessage id="menu.aboutUs" />
        </h2>
        <span className={styles.linkContainer}>
          <FormattedMessage id="aboutUs.text" />
          <a
            href="https://www.msfint.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="aboutUs.manos" />
          </a>
          <FormattedMessage id="aboutUs.textSecond" />
        </span>
        <FormattedMessage id="aboutUs.textThird" />
        <FormattedMessage id="aboutUs.textFourth" />
        <p className={styles.quote}>
          <FormattedMessage id="aboutUs.LJQuote" />
        </p>
        <div className={styles.logoContainer}>
          <img src={LJLogo} alt="Logo La Jardinera" />
        </div>
        <div className={styles.copyRight}>
          <FormattedMessage id="aboutUs.copyright" />
        </div>
      </div>
    </div>
  </ContentWrapper>
);

AboutUsPage.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AboutUsPage);
